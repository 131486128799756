import {
  Box,
  Card,
  CardContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { getLoyaltyPoints } from "../../service/loyaltyinfo.service";
import STRING_CONSTANTS from "../../shared/constants/StringConstants";
import { LoyaltyPointsCard } from "../../styles/styledComponent";
import LoyaltyPointsChart from "./LoyaltyPointsChart";
import useAppConfig from "../../hooks/useAppConfig";
import TableWithAPI from "./TableWithApi";
import { get } from "lodash";
import { toast } from "react-hot-toast";
import { doesUserHaveImmediateExpiringPoints } from "../../service/loyalty-points";
import moment from "moment";
import _ from "lodash";
import LoyaltyIcon from "../../assets/loyaltyIcon";

const LoyaltyPoints = () => {
  const [loyaltyInfo, setLoyaltyInfo] = useState({});
  const config = useAppConfig();
  const [multiplier, setMultiplier] = useState(1);

  const selectedBusiness = get(config, "appConfig.selecteBussines.name");
  useEffect(() => {
    selectedBusiness &&
      getLoyaltyPoints(selectedBusiness).then((res) => {
        setLoyaltyInfo(res.data.loyaltyDetails);
        setMultiplier(res?.data?.activeMultiplier?.redeemMultiplier);
      });
  }, [selectedBusiness]);

  const getFormattedExpiryPointsToast = ({
    expiringpoints,
    max_expire_date,
  }) => {
    const formattedDate = moment(max_expire_date).fromNow();
    return `${expiringpoints} points are going to expire ${formattedDate}`;
  };

  useEffect(() => {
    if (doesUserHaveImmediateExpiringPoints(config)) {
      toast(
        getFormattedExpiryPointsToast(
          get(config, "loyaltyDetails.immediateExpiringPoints", {})
        )
      );
    }
    // eslint-disable-next-line
  }, []);
  const getTierCompletionPercentage = () => {
    const percentage =
      (loyaltyInfo.pastBillAmount / loyaltyInfo.maxOffset) * 100;
    return percentage > 95 ? 95 : percentage;
  };
  const formatDate = (dateString, days) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + days);
    return date.toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <>
      {loyaltyInfo?.response?.data?.status !== "failure" &&
        !_.isEmpty(loyaltyInfo) && (
          <Card>
            {loyaltyInfo.maxOffset === -1 ? (
              <CardContent>
                <Box display="flex" justifyContent="center" pb={1}>
                  <Typography fontSize={16} fontWeight={600}>
                    {"Current Tier"}
                  </Typography>
                  <Typography fontSize={16} fontWeight={600}>
                    {loyaltyInfo.currentTier.toUpperCase()}
                  </Typography>
                </Box>
                <Box
                  sx={{ width: "100%", borderRadius: 8, overflow: "hidden" }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={100}
                    sx={{ height: 10, borderRadius: 5 }}
                  />
                </Box>
                <Typography fontSize={12} mt={1} textAlign={"center"}>
                  You are in the highest tier
                </Typography>
              </CardContent>
            ) : loyaltyInfo.pastBillDate === "-" ? (
              <CardContent>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <LoyaltyIcon
                    backgroundColor={
                      config?.appConfig?.config?.palette?.primary
                    }
                  />
                </Box>
                <Typography
                  fontSize={16}
                  mt={1}
                  textAlign={"center"}
                  sx={{ color: config?.appConfig?.config?.palette?.primary }}
                >
                  Earn rewards as you shop!
                </Typography>
                <Typography fontSize={16} mt={1} textAlign={"center"}>
                  Unlock exclusive perks with every tier
                </Typography>
              </CardContent>
            ) : (
              <CardContent>
                <Box display="flex" justifyContent="space-between" pb={1}>
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                  >
                    <Typography fontSize={16} fontWeight={400}>
                      {"Current Tier"}
                    </Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      {loyaltyInfo.currentTier.toUpperCase()}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={"column"}
                    alignItems={"flex-end"}
                  >
                    <Typography fontSize={16} fontWeight={400}>
                      {"Next Tier"}
                    </Typography>
                    <Typography fontSize={16} fontWeight={600}>
                      {loyaltyInfo.nextTier.toUpperCase()}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{ width: "100%", borderRadius: 8, overflow: "hidden" }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={getTierCompletionPercentage()}
                    sx={{ height: 10, borderRadius: 5 }}
                  />
                </Box>
                <Typography fontSize={12} mt={1}>
                  Spend{" "}
                  <Typography
                    component="span"
                    fontWeight={600}
                    fontSize={12}
                    sx={{ color: config?.appConfig?.config?.palette?.primary }}
                  >
                    Rs. {loyaltyInfo.maxOffset - loyaltyInfo.pastBillAmount + 1}
                  </Typography>{" "}
                  more before{" "}
                  {formatDate(
                    loyaltyInfo.pastBillDate,
                    loyaltyInfo.billHistoryDays
                  )}{" "}
                  to unlock{" "}
                  <Typography
                    component="span"
                    fontWeight={600}
                    fontSize={12}
                    sx={{ color: config?.appConfig?.config?.palette?.primary }}
                  >
                    {loyaltyInfo.nextTier.toUpperCase()}
                  </Typography>{" "}
                  tier
                </Typography>
              </CardContent>
            )}
          </Card>
        )}
      {loyaltyInfo?.response?.data?.status !== "failure" &&
        !_.isEmpty(loyaltyInfo) && (
          <LoyaltyPointsCard>
            <CardContent>
              <header>
                <Box>
                  <Typography style={{ fontWeight: 500 }}>
                    {STRING_CONSTANTS.LOYALTY_POINTS}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ fontWeight: 600 }}
                  >
                    {loyaltyInfo?.active?.toString() || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ fontWeight: 500 }}>
                    {`Points With Multiplier (x${multiplier})`}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ fontWeight: 600 }}
                  >
                    {loyaltyInfo?.activePointsWithMultiplier?.toString() || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography style={{ fontWeight: 500 }}>
                    {STRING_CONSTANTS.LOYALTY_LEVEL}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    style={{ fontWeight: 600 }}
                  >
                    {loyaltyInfo?.currentTier || "-"}
                  </Typography>
                </Box>
              </header>
              <Box className="loyaltyChart">
                <LoyaltyPointsChart
                  earned={loyaltyInfo?.active || 0}
                  redeemed={loyaltyInfo?.burned || 0}
                  expired={loyaltyInfo?.expired || 0}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography style={{ fontSize: 18 }}>Total Earned: </Typography>
                <span
                  style={{
                    fontWeight: 700,
                    marginLeft: 4,
                    color: "#F26E5F",
                    fontSize: 18,
                  }}
                >
                  {" "}
                  {loyaltyInfo?.earned?.toString() || ""}
                </span>
              </Box>
            </CardContent>
          </LoyaltyPointsCard>
        )}

      <Typography style={{ textAlign: "center", fontWeight: 600 }}>
        Loyalty Points Details
      </Typography>
      <LoyaltyPointsCard>
        <TableWithAPI />
      </LoyaltyPointsCard>
    </>
  );
};

export default LoyaltyPoints;
